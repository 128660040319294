var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AuthTransaction } from '../tx';
import { IdxStatus, } from '../types';
import { run } from './run';
import { Identify, SelectAuthenticator, EnrollOrChallengeAuthenticator, AuthenticatorVerificationData, } from './remediators';
import { interact } from './interact';
const flow = {
    'identify': Identify,
    'identify-recovery': Identify,
    'select-authenticator-authenticate': SelectAuthenticator,
    'challenge-authenticator': EnrollOrChallengeAuthenticator,
    'authenticator-verification-data': AuthenticatorVerificationData,
    'reset-authenticator': EnrollOrChallengeAuthenticator,
};
export function recoverPassword(authClient, options) {
    return __awaiter(this, void 0, void 0, function* () {
        let error;
        let status;
        try {
            const interactResponse = yield interact(authClient, options);
            const idxResponse = interactResponse.idxResponse;
            const shouldIdentify = idxResponse.neededToProceed.some(({ name }) => name === 'identify')
                && !Object.keys(idxResponse.actions).includes('currentAuthenticator-recover');
            if (shouldIdentify) {
                options = Object.assign(Object.assign({}, options), { 
                    // When set any factor as primary factor in policy
                    // Select password authenticator first to start the recovery password flow
                    authenticators: ['password', ...(options.authenticators || [])] });
            }
            return run(authClient, Object.assign(Object.assign({}, options), { flow, actions: [
                    'currentAuthenticator-recover',
                    'currentAuthenticatorEnrollment-recover'
                ] }), interactResponse);
        }
        catch (err) {
            error = err;
            status = IdxStatus.FAILED;
            // Clear transaction meta when error is not handlable
            authClient.transactionManager.clear();
        }
        const authTransaction = new AuthTransaction(authClient, {
            status,
            error,
        });
        return authTransaction;
    });
}
